<style scoped>

.navbar {
  border-bottom: 0.1rem solid #00d1b2;
}

.navbar-brand img {
  position: absolute;
  top: -1em;
  left: 0;
  max-height: 146px !important;
  max-width: 95px !important;
  width: 80px !important;
  height: 110px !important;
}

.mobile-brand {
  font-size: 1.2rem;
  font-weight: 800;
}

.indented-menus {
  margin-left: 1rem !important;
  border-left: 3px solid #F7F9F9;
}

</style>

<template>

  <nav class="navbar is-fixed-top is-spaced">
    <div class="navbar-brand">
      <Link class="navbar-item is-hidden-mobile mr-6" href="/">
        <img src="../../images/brand.png" />
      </Link>
      <div class="navbar-item p-0 is-hidden-tablet">
        <span v-if="$page.props.auth" class="is-flex is-active">
          <a id="SideBarMenu" class="navbar-burger" @click="isSideMenuActive = !isSideMenuActive" :class="{'is-active':isSideMenuActive}">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
          <Link class="mt-3 mobile-brand" href="/">{{ url.hostname }}</Link>
        </span>
        <span v-else>
          <span class="ml-5">&nbsp;</span>
          <Link class="mobile-brand" href="/">{{ url.hostname }}</Link>
        </span>
      </div>

      <a class="navbar-burger" data-target="navMenu" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navMenu" class="navbar-menu" :class="{ 'is-active': isActive }">
      <div class="navbar-start">
        <div class="navbar-item">
          <Messenger v-if="$page.props.agents.length" />
          <SearchPropertiesByAddress v-if="$page.props.auth" @open-listing="openListing" />
        </div>
      </div>

      <div class="navbar-end">

        <NavbarNotifications v-if="$page.props.auth" :auth="$page.props.auth" />

        <template v-if="$page.props.auth">

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              {{ $page.props.auth.first_name }}
              <span class="is-size-7 ml-1 is-uppercase">({{ $page.props.auth.role_text }})</span>
            </a>

            <div class="navbar-dropdown is-right">
              <Link class="navbar-item" :href="route('user-dashboard')">My Dashboard</Link>
              <Link class="navbar-item" :href="route('change-password.edit')">Change Password</Link>

              <template v-if="$page.props.auth.is_super_admin">
                <hr class="navbar-divider">
                <h4 class="navbar-item has-text-weight-bold">User Activities</h4>
                <div class="indented-menus">
                  <Link class="navbar-item" :href="route('user-activity-logs.daily', { tz: currentTimezone })">User Daily Statistics</Link>
                  <Link class="navbar-item" :href="route('user-activity-logs.index', { tz: currentTimezone })">User Activity Logs</Link>
                </div>
                <hr class="navbar-divider">
                <h4 class="navbar-item has-text-weight-bold">Toronto MLS RETS</h4>
                <div class="indented-menus">
                  <Link class="navbar-item" :href="route('mls-download-sessions.daily')">MLS Download Sessions - Daily</Link>
                  <Link class="navbar-item" :href="route('mls-download-sessions.index')">MLS Download Sessions - All</Link>
                  <!-- <Link class="navbar-item" :href="route('listing-verifier.show')">MLS Listing Checker</Link>
                  <a class="navbar-item" @click.prevent="listingDownloaderDialog.open">MLS Listing Downloader</a> -->
                </div>
              </template>

              <template v-if="$page.props.auth.is_super_admin">
                <hr class="navbar-divider">
                <h4 class="navbar-item has-text-weight-bold">Reports</h4>
                <div class="indented-menus">
                  <Link class="navbar-item" :href="route('reports.buildings.data-input-progress')">Building Data Input Progress</Link>
                  <Link class="navbar-item" :href="route('reports.listings.downloaded.municipal')">Municipal Downloaded Listings</Link>
                  <Link class="navbar-item" :href="route('reports.listings.downloaded.yearly')">Yearly Downloaded Listings</Link>
                </div>
              </template>
              <template v-else-if="$page.props.can.buildings_view_admin">
                <hr class="navbar-divider">
                <h4 class="navbar-item has-text-weight-bold">Reports</h4>
                <div class="indented-menus">
                  <Link class="navbar-item" :href="route('reports.buildings.data-input-progress')">Building Data Input Progress</Link>
                </div>
              </template>

              <hr class="navbar-divider">
              <a class="navbar-item" @click.prevent="logout">Logout</a>
            </div>
          </div>
        </template>
        <template v-else>
          <Link v-if="$page.url != '/login'" :href="route('login')" class="navbar-item has-text-weight-bold has-text-primary-dark mx-2">Log in</Link>
          <Link v-if="$page.url != '/register'" :href="route('register')" class="navbar-item has-text-weight-bold button is-reg mx-2">Register</Link>
        </template>
      </div>
    </div>
  </nav>

  <ListingDownloaderDialog ref="listingDownloaderDialog" />
  <ShowListingDialog ref="showListingDialog" />

</template>

<script setup>

import { router, } from '@inertiajs/vue3';
import { ref, onUnmounted, watch } from 'vue';

import SearchPropertiesByAddress from './SearchPropertiesByAddress.vue';
import ShowListingDialog from '../Pages/Listing/Components/ShowListingDialog.vue';
import NavbarNotifications from './NavbarNotifications.vue';
import ListingDownloaderDialog from './ListingDownloaderDialog.vue';
import Messenger from './Messenger.vue';

const listingDownloaderDialog = ref(null)
const isActive = ref(false)
const isSideMenuActive = ref(screen.width > 760)
const url = ref(window.location.href)
const showListingDialog = ref()
const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

onUnmounted(
  router.on('before', () => {
    document.activeElement.blur();
    isActive.value = false
    isSideMenuActive.value = false
  })
);

onUnmounted(
  router.on('success', () => {
    url.value = window.location.href
  })
);

watch(isSideMenuActive, value => {
  document.dispatchEvent(new CustomEvent('nav:side-menu', { detail: { value } }))
});

function logout() {
  router.post(route('logout'));
}

function openListing(e) {
  showListingDialog.value.show(e)
}

</script>
