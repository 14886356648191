<style scoped>
@media (min-width: 768px) {
  .input {
    width: 30em;
  }
}
</style>

<template>
  <div class="dropdown" :class="{ 'is-active': listings.length }">

    <div class="dropdown-trigger">
      <div class="field">
        <div class="control is-expanded is-large" :class="{'is-loading':isSearching}">
          <input v-model="searchText" class="input is-primary is-rounded"
            type="search" placeholder="Search property"
            @blur="clearListings" @focus="autoComplete">
        </div>
      </div>
    </div>

    <div class="dropdown-menu">
      <div class="dropdown-content">
        <div v-if="listings.length == 1 && listings[0].id == -1" class="dropdown-item">
          No results for this search.
        </div>
        <a href="/" v-else v-for="listing in listings" :key="listing.id"
          @click.prevent="openListing(listing)" class="dropdown-item">
          <div class="columns is-mobile">
            <div class="column is-7">
              <span v-html="highlight(listing.address)"></span>
            </div>
            <div class="column is-1">
              <span v-if="listing.status" class="is-size-7"
                :class="getListingTextColor(listing)">
                {{ listing.status }}
              </span>
            </div>
            <div class="column is-1 mr-2">
              <span class="is-family-code is-size-7 has-text-grey">{{ formatter.date(listing.date, 'MM/DD/YYYY') }}</span>
            </div>
            <div class="column is-3 has-text-right ml-3">
              <strong>{{ formatter.currency(listing.price) }}</strong>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>

import axios from 'axios';
import { ref, watch } from 'vue';
import debounce from 'lodash/debounce';

import { createFormatter } from '../Scripts/formatter.js';
import { getListingTextColor } from '../Scripts/listing.js';

const emits = defineEmits(['open-listing'])
const isSearching = ref(false)
const searchText = ref('')
const listings = ref([])
const formatter = createFormatter()

watch(searchText, debounce(autoComplete, 700));

function autoComplete() {
  if (searchText.value) {
    isSearching.value = true
    axios.get(route('properties.listings.search', {
      address: searchText.value,
    }))
      .then(res => {
        listings.value = res.data.filter(l => l.is_public && l.display_address);
        if (listings.value.length == 0) {
          listings.value.push({ id: -1 })
        }
      })
      .finally(() => {
        isSearching.value = false;
      });
  }
  else {
    listings.value = [];
  }
}

function clearListings() {
  setTimeout(() => listings.value = [], 300)
}

function highlight(text) {
  const search = searchText.value;

  if (!search)
    return text;

  const matches = search.match(/\w+/g);
  let value = text;

  matches.sort((a, b) => b.length - a.length);
  matches.forEach(match => {
    value = value.replace(
      new RegExp(match, 'gi'),
      str => `<b>${str}</b>`
    );
  });

  return value;
}

function openListing(listing) {
  emits('open-listing', listing)
}

</script>
