<template>

  <div class="field">

    <label class="label is-flex">
      <span>{{ label }}</span>
      <i v-if="required" class="has-text-danger" data-tooltip="Required">*</i>
      <span v-if="lastEditor" class="is-size-7 ml-1 has-text-success" :title="lastEditor.name">({{ lastEditor.last_name }})</span>
    </label>

    <div class="control has-icons-left">
      <input v-model="form[field]" class="input" type="tel"
        :required="required" :readonly="readonly" :disabled="disabled"
        pattern="^[0-9\+\- \(\)]*( ext)?[\.]?( [0-9]+)?$">
      <span class="icon is-left">
        <i class="fas fa-phone"></i>
      </span>
    </div>
    <p class="help has-text-grey is-italic">* Only numbers, the word 'ext', - and + characters allowed.</p>
    <div class="help is-danger" v-if="$page.props.errors[field]">{{ $page.props.errors[field] }}</div>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

const props = defineProps({
  form: Object,
  label: {
    type: String,
    default: 'Phone Number',
  },
  field: {
    type: String,
    default: 'phone_number',
  },
  required: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  lastEditor: Object,
});

const internationFormat = ref(false);
const format = ref('');

watch(internationFormat, value => {
  if (value)
    format.value = '#-###-###-####';
  else
    format.value = '###-###-####';
});

internationFormat.value = props.form[props.field] && props.form[props.field].length == 13;


</script>
