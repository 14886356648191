<template>

  <template v-if="amenities.length">
    <h4 class="mb-1 is-size-7 has-background-success-light pl-2 has-text-success-dark has-text-weight-bold">AMENITIES</h4>
    <div class="tags ml-5">
      <span v-for="amenity in amenities" :key="amenity" class="tag is-success is-light">
        <span class="icon-text">
          <span class="icon">
            <i class="far fa-circle-check"></i>
          </span>
          <span>{{ amenity }}</span>
        </span>
      </span>

      <span v-if="mlsDump(listing, 'Furnished') == 'Y'" class="tag is-primary">
        <span class="icon-text">
          <span class="icon">
            <i class="far fa-circle-check"></i>
          </span>
          <span>Furnished</span>
        </span>
      </span>
      <span v-if="mlsDump(listing, 'Pvt_ent') == 'Y'" class="tag is-primary">
        <span class="icon-text">
          <span class="icon">
            <i class="far fa-circle-check"></i>
          </span>
          <span>Private Entrance</span>
        </span>
      </span>
    </div>
  </template>

  <template v-if="inclusions.length">
    <h4 class="mb-1 is-size-7 has-background-info-light pl-2 has-text-info-dark has-text-weight-bold">INCLUSIONS</h4>
    <div class="tags ml-5">
      <span v-for="inclusion in inclusions" :key="inclusion" class="tag is-info is-light">
        <span class="icon-text">
          <span class="icon">
            <i class="far fa-circle-check"></i>
          </span>
          <span>{{ inclusion }}</span>
        </span>
      </span>
    </div>
  </template>

  <template v-if="features.length">
    <h4 class="mb-1 is-size-7 has-background-success-light pl-2 has-text-success-dark has-text-weight-bold">FEATURES</h4>
    <div class="tags ml-5">
      <span v-for="feature in features" :key="feature" class="tag is-success is-light">
        <span class="icon-text">
          <span class="icon">
            <i class="far fa-circle-check"></i>
          </span>
          <span>{{ feature }}</span>
        </span>
      </span>
    </div>
  </template>

</template>

<script setup>
import { ref } from "@vue/reactivity";
import { mlsDump } from '../../../Scripts/listing.js';

const props = defineProps({
  listing: Object,
  formatter: Object,
});

const inclusions = ref(getInclusions());
const amenities = ref(getAmenities());
const features = ref(getFeatures());

function getAmenities() {
  const values = [];
  for (let i = 1; i <= 6; i++) {
    let amen = mlsDump(props.listing, `Bldg_amen${i}_out`, '');
    if (amen)
      values.push(amen);
  }
  return values;
}

function getInclusions() {
  const values = [];

  if (mlsDump(props.listing, 'Water_inc', null))
    values.push('Water');
  if (mlsDump(props.listing, 'Heat_inc', null))
    values.push('Heat');
  if (mlsDump(props.listing, 'Hydro_inc', null))
    values.push('Hydro');
  if (mlsDump(props.listing, 'Cable', null))
    values.push('Cable');
  if (mlsDump(props.listing, 'Cac_inc', null))
    values.push('A/C');
  if (mlsDump(props.listing, 'Insur_bldg', null))
    values.push('Building Insurance');
  if (mlsDump(props.listing, 'Prkg_inc', null))
    values.push('Parking');
  if (mlsDump(props.listing, 'Comel_inc', null))
    values.push('Common Elements');

  return values;
}

function getFeatures() {
  const values = [];
  if (mlsDump(props.listing, 'Elevator') == 'Y')
    values.push('Elevator')
  if (mlsDump(props.listing, 'Den_fr') == 'Y')
    values.push('Family Room')
  for (let i = 1; i < 10; i++) {
    let feature = mlsDump(props.listing, `Prop_feat${i}_out`, '');
    if (feature)
      values.push(feature);
  }
  return values;
}

</script>
