<style scoped>
.navbar-dropdown {
  width: 25em;
}
.navbar-dropdown .navbar-item {
  white-space: normal;
}
a.navbar-item {
  padding-right: 1em;
}
</style>

<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link is-arrowless">
      <span class="icon" :class="{ 'has-text-link': unreadNotifications.length }">
        <i class="fas fa-bell" :class="{'has-ring-animation': unreadNotifications.length}"></i>
      </span>
      <span v-if="unreadNotifications.length" class="tag is-link">{{ unreadNotifications.length }}</span>
    </a>

    <div class="navbar-dropdown is-right">
      <p class="navbar-item has-text-weight-bold is-size-5 has-text-primary-dark">My Notifications</p>
      <hr class="navbar-divider">
      <p class="navbar-item" v-if="unreadNotifications.length == 0">There are no new notifications at this time.</p>
      <a v-for="notification in unreadNotifications" :key="notification.id" @click.stop="takeAction(notification)" class="navbar-item">

        <article class="media">

          <div class="media-content">
            <div class="content">
              <p class="has-text-info-dark">
                {{ notification.message }}
                <span class="has-text-grey is-size-7 pb-5">({{ moment(notification.created_at).fromNow() }})</span>
              </p>
            </div>
          </div>
          <div class="media-right has-text-right">
            <a @click.stop="markAsRead(notification)">
              <span class="icon px-5" title="Mark as read">
                <i class="fas fa-eye"></i>
              </span>
            </a>
          </div>
        </article>

      </a>
      <template v-if="unreadNotifications.length">
        <hr class="navbar-divider">
        <a class="navbar-item" @click.stop="markAllAsRead">
          Mark all as read
        </a>
      </template>

      <hr class="navbar-divider">
      <Link class="navbar-item has-text-info" :href="route('notifications.index')">View All Notifications</Link>
    </div>
  </div>
  <Transition name="slide-fade">
    <a v-if="floatingNotification" class="notification is-floating is-link" @click.stop="takeAction(floatingNotification)">
      <button class="delete" @click="floatingNotification = false"></button>
      {{ floatingNotification.message }}
    </a>
  </Transition>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import axios from "axios";
import moment from "moment";

const props = defineProps({
  auth: Object
});

const notifications = ref([]);
const floatingNotification = ref(false);

const unreadNotifications = computed(() => notifications.value ? notifications.value.filter(n => !n.is_read) : []);

Echo.private(`App.Models.User.${props.auth.id}`)
  .notification(notification => {
    notifications.value.unshift(notification);
    floatingNotification.value = notification
    setTimeout(() => floatingNotification.value = false, 5000)
  });

axios.get(route('notification-read.index'))
  .then(res => {
    if (Array.isArray(res.data)) {
      notifications.value = res.data
    }
  });

function markAsRead(notification) {
  axios.post(route('notification-read.store', { notification: notification.id }))
    .then(() => notification.is_read = true);
}

function markAllAsRead() {
  axios.post(route('notification-read.store'))
    .then(() => notifications.value = []);
}

function takeAction(notification) {
  markAsRead(notification);
  if (notification.action) {
    window.location.href = notification.action;
  }
  else {
    router.get(route('notifications.index'));
  }
}

</script>
