<template>
  <div class="columns">
    <div class="column">
      <div class="columns has-text-weight-bold is-gapless is-mobile is-flex-wrap-wrap">
        <div v-if="listing.is_active" class="column is-narrow">
          <span class="tag is-success mr-5 has-text-weight-normal">
            For {{ listing.sr_status }}
          </span>
        </div>
        <div class="column is-narrow">
          <span class="icon-text has-tooltip-arrow mr-5" data-tooltip="Bedrooms/Dens">
            <span class="icon">
              <i class="fas fa-bed"></i>
            </span>
            <span>
              {{ listing.bedrooms_count }}
              <template v-if="listing.bedrooms_plus_count">/{{ listing.bedrooms_plus_count }}</template>
            </span>
          </span>
        </div>
        <div class="column is-narrow">
          <span class="icon-text has-tooltip-arrow mr-5" data-tooltip="Bathrooms">
            <span class="icon">
              <i class="fas fa-bath"></i>
            </span>
            <span>{{ listing.bathrooms_count }}</span>
          </span>
        </div>
        <div class="column is-narrow">
          <span class="icon-text has-tooltip-arrow mr-5" data-tooltip="Kitchens/+">
            <span class="icon">
              <i class="fas fa-kitchen-set"></i>
            </span>
            <span>
              {{ listing.kitchens_count }}
              <template v-if="mlsDump(listing, 'Kit_plus', null)">/{{ mlsDump(listing, 'Kit_plus') }}</template>
            </span>
          </span>
        </div>
        <div class="column is-narrow">
          <span v-if="mlsDump(listing, 'Tot_park_spcs', null)"
            class="icon-text has-tooltip-arrow mr-5" data-tooltip="Parking Spaces">
            <span class="icon">
              <i class="fas fa-car"></i>
            </span>
            <span>{{ parseInt(mlsDump(listing, 'Tot_park_spcs')) }}</span>
          </span>
        </div>
        <div class="column is-narrow">
          <span v-if="mlsDump(listing, 'Sqft', null)" class="icon-text has-tooltip-arrow mr-5"
            data-tooltip="Square feet">
            <span class="icon">
              <i class="fas fa-ruler"></i>
            </span>
            <span>{{ mlsDump(listing, 'Sqft') }} sqft</span>
          </span>
        </div>
      </div>
    </div>
    <div class="column is-narrow">
      <div class="buttons are-small">
        <button ref="favorite" class="button is-danger is-light"
          :class="{'is-loading': isFavoring}"
          @click="favoriteListing">
          <span class="icon">
            <i v-if="isFavoriteListing" class="fas fa-heart"></i>
            <i v-else class="far fa-heart"></i>
          </span>
          <span v-if="isFavoriteListing">UNFAVORITE</span>
          <span v-else>FAVORITE</span>
        </button>
        <a v-if="tourUrl" class="button is-success is-light" :href="tourUrl" target="_blank">
          <span class="icon">
            <i class="fas fa-eye"></i>
          </span>
          <span>VIRTUAL TOUR</span>
        </a>
        <button class="button is-info is-light" @click="getCoordinates" :class="{'is-loading':isGeoCoding}">
          <span class="icon">
            <i class="fas fa-map"></i>
          </span>
          <span>MAP</span>
        </button>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showMapModal}">
    <div class="modal-background" @click="showMapModal = false"></div>
    <div class="modal-content">
      <MapView v-if="showMapModal" :position="mapPosition" />
    </div>
    <button class="modal-close is-large" @click="showMapModal = false"></button>
  </div>

</template>

<script setup>
import { ref } from "@vue/reactivity";

import MapView from "../../../Components/MapView.vue";
import { toggleFavoriteListing, mlsDump } from '../../../Scripts/listing.js';
import { geocode } from '../../../Scripts/utils';

const props = defineProps({
  listing: Object,
  favoriteListingIds: Array,
  formatter: Object,
});

const isFavoriteListing = ref(props.favoriteListingIds?.indexOf(props.listing.id) != -1);
const isFavoring = ref(false);
const favorite = ref(null);
const tourUrl =  mlsDump(props.listing, 'Tour_url', null);

function favoriteListing() {
  isFavoring.value = true;
  toggleFavoriteListing(props.listing.id, isFavoriteListing.value)
    .then(res => {
      isFavoriteListing.value = res;
      isFavoring.value = false;
      favorite.value.blur();
    });
}

const showMapModal = ref(false);
const mapPosition = ref(false);
const isGeoCoding = ref(false);

function getCoordinates() {

  if (mapPosition.value) {
    showMapModal.value = true;
    return;
  }

  isGeoCoding.value = true;

  geocode(props.listing.address).then(res => {
    mapPosition.value = res[0].geometry.location;
    showMapModal.value = true;
  })
  .catch(err => {
    alert('Unable to find the address.')
  })
  .finally(() => {
      isGeoCoding.value = false;
  });
}

</script>
