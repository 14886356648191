import { router } from '@inertiajs/vue3';
import axios from "axios";
import moment from "moment";

export function toggleFavoriteListing(listingId, isFavoriteListing) {

  function redirectToLoginPage() {
    router.get(route('login') + '?redirect=' + encodeURIComponent(window.location.pathname));
  }

  return new Promise((resolve, reject) => {

    if (isFavoriteListing) {

      axios.delete(route('favorite-listings.destroy', { id: listingId }))
        .then(res => {
          if (!res.data.success)
            return router.get(route('login'));
          resolve(false);
        })
        .catch(err => {
          alert(err.response.statusText)
        })
    }
    else {

      axios.post(route('favorite-listings.store', { id: listingId }))
        .then(res => {
          if (!res.data.success)
            redirectToLoginPage();
          resolve(true);
        })
        .catch(err => {
          if (err.response.status == 401) {
            redirectToLoginPage();
          }
          else {
            console.log(err.response);
            reject(err);
          }
        })
    }
  });
}

const now = new Date();
export function isListingExpired(listing) {
  if (listing.expiry_date) {
    const mom = moment(new Date(listing.expiry_date.slice(0, -1)));
    return mom.toDate() < now;
  }
  return false;
}

export function getListingTextColor(listing) {

  switch (listing.status) {
    case 'Pc': return 'has-text-success';
    case 'Exp':
    case 'Ter':
    case 'Sus': return 'has-text-grey';
    case 'Sc':
    case 'Sce':
    case 'Lc':
    case 'Lce': return 'has-text-orange';
    case 'Lsd':
    case 'Sld': return 'has-text-danger';
  }

  return 'has-text-black';
}

export function getListingBackgroundColor(listing, hex) {

  if (hex) {
    switch (listing.status) {
      case 'Pc': return '#48c78e';
      case 'Exp':
      case 'Ter':
      case 'Sus': return '#7a7a7a';
      case 'Sc':
      case 'Sce':
      case 'Lc':
      case 'Lce': return '#e69310';
      case 'Lsd':
      case 'Sld': return '#f14668';
    }
  }
  else {
    switch (listing.status) {
      case 'Pc': return 'has-background-success';
      case 'Exp':
      case 'Ter':
      case 'Sus': return 'has-background-grey';
      case 'Sc':
      case 'Sce':
      case 'Lc':
      case 'Lce': return 'has-background-orange';
      case 'Lsd':
      case 'Sld': return 'has-background-danger';
    }
  }

  return '';
}

export async function downloadListingImages(listing) {
  const res = await axios
    .get(route('listing-images.show', { mls: listing.mls_listing.id }));

  return res.data;
}

export function getListingStreetAddress(listing) {
  let address = listing.address;
  if (listing.unit_num) {
    address = listing.unit_num + '-' + address;
  }
  return address;
}

export function getListingAddress(listing) {
  let address = [
    listing.address, listing.area, listing.county
  ].filter(Boolean).join(', ');

  if (listing.unit_num) {
    address = listing.unit_num + '-' + address;
  }

  return address;
}

export function mlsDump(listing, keys, def, separator) {

  if (def === undefined)
    def = '-';
  if (separator === undefined)
    separator = ' ';

  let value = null;

  if (Array.isArray(keys)) {
    const values = keys.map(k => mlsDump(listing, k));
    value = values.filter(v => v != '-').join(separator);
  }
  else {
    value = listing.mls_listing.data[keys];
  }

  return value || def;
}
