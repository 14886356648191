<template>

  <button v-if="listing" class="button is-primary inquire is-fullwidth" @click="openDialog">
    <span>Send Inquiry</span>
  </button>

  <button v-else class="button is-primary inquire is-medium has-text-weight-semibold is-rounded"
    @click="openDialog">
    <span class="icon">
      <i class="fas fa-envelope"></i>
    </span>
    <span>Contact Us</span>
  </button>


  <Dialog ref="dialog" :title="listing ? 'Property Inquiry' : 'General Inquiry'" @oked="recaptcha"
    ok-text="Send Inquiry">
    <template #content>
      <form ref="formElement">
        <div class="columns">
          <div class="column">
            <InputString :form="form" label="First Name" field="first_name" required />
          </div>
          <div class="column">
            <InputString :form="form" label="Last Name" field="last_name" required />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <InputEmail :form="form" label="Email" field="email" required />
          </div>
          <div class="column">
            <InputPhone :form="form" label="Phone Number" field="phone_number" required />
          </div>
        </div>
        <InputTextArea :form="form" label="Message" field="message" type="textarea" required />
      </form>
      <p class="help is-danger" v-if="form.errors.captcha_token">{{ form.errors.captcha_token }}</p>
      <p class="help">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
        <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
      </p>
    </template>
  </Dialog>

</template>

<script setup>

import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import { ref } from '@vue/reactivity';
import { useReCaptcha } from 'vue-recaptcha-v3';

import Dialog from './Dialog.vue';
import InputString from './Inputs/InputString.vue';
import InputEmail from './Inputs/InputEmail.vue';
import InputPhone from './Inputs/InputPhone.vue';
import InputTextArea from './Inputs/InputTextArea.vue';

const props = defineProps({
  listing: Object,
});

const user = router.page.props.auth;
const form = useForm({
  first_name: null,
  last_name: null,
  email: null,
  phone_number: user?.phone_number,
  message: null,
  captcha_token: null,
});

if (user) {
  form.first_name = user.first_name;
  form.last_name = user.last_name;
  form.email = user.email;
  form.defaults();
}

const dialog = ref(null);
const formElement = ref(null);
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

const recaptcha = async () => {
  await recaptchaLoaded();
  form.captcha_token = await executeRecaptcha('login');
  inquire();
}

function openDialog() {
  dialog.value.open();
  form.clearErrors();
}

function inquire() {

  if (!formElement.value.reportValidity()) {
    dialog.value.stopLoading();
    return;
  }

  form.post(route('inquiry.store', props.listing ? { listing: props.listing.id } : null), {
    preserveState: true,
    preserveScroll: true,
    onSuccess() {
      dialog.value.close();
    },
    onFinish() {
      dialog.value.stopLoading();
    }
  })
}

</script>
