<style scoped>
  figure {
    background-color: inherit;
  }
  figure.is-clickable img {
    height: 10rem;
    width: 20rem;
  }
  .marker {
    display: block;
    height: 0.5rem;
    border-radius: 0.3rem;
  }
</style>

<template>
  <div>
    <Carousel :items-to-show="1" v-model="currentImageIndex">
      <Slide v-for="image in images" :key="image.id">
        <a :href="image.url" @click.prevent="$refs.imageModal.open()">
          <figure class="image">
            <vLazyImage :src="image.url" :alt="image.name" />
          </figure>
        </a>
      </Slide>
    </Carousel>
    <Carousel v-if="showThumbNav" :items-to-show="2.5" class="mt-2">
      <Slide v-for="(image, index) in images" :key="image.id">
        <figure class="is-clickable image mr-1" @click="currentImageIndex = index">
          <vLazyImage :src="image.url" :alt="image.name" />
          <span class="marker mt-2"
            :class="{'has-background-primary-dark': currentImageIndex == index}"></span>
        </figure>
      </Slide>
      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 1" />
      </template>
    </Carousel>

    <ImageModal ref="imageModal" :images="images" :initial-image-index="currentImageIndex" />
  </div>
</template>

<script setup>

import { ref } from 'vue';

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import vLazyImage from 'v-lazy-image';

import ImageModal from './ImageModal.vue';

const props = defineProps({
  images: Array,
  showThumbNav: Boolean
});
const currentImageIndex = ref(0);

</script>
