import axios from "axios";
import { onBeforeUnmount, ref } from "vue";

export function detectIsMobile() {
  const isMobile = ref(false);

  const updateIsMobile = (event) => {
    isMobile.value = event.matches;
  };

  const mediaQuery = window.matchMedia('(max-width: 767px)');

  isMobile.value = mediaQuery.matches;
  mediaQuery.addEventListener('change', updateIsMobile);

  onBeforeUnmount(() => {
    mediaQuery.removeEventListener('change', updateIsMobile);
  });

  return isMobile;
}

export function geocode(address) {

  return new Promise((resolve, reject) => {
    axios.post(
      route('geocode', { address }))
      .then(res => {
        if (res.data.status == 'OK') {
          resolve(res.data.results);
        }
        else {
          reject('Unable to find address.')
        }
      })
      .catch(err => {
        reject('Unable to find the address.')
      });
  });
}

export function groupArray(array, size) {
  const result = [];
  const totalGroups = Math.ceil(array.length / size);

  for (let i = 0; i < totalGroups; i++) {
    const startIndex = i * size;
    const endIndex = startIndex + size;
    const group = array.slice(startIndex, endIndex);
    result.push(group);
  }

  return result;
}

export function buildPropValues(obj, table) {
  const propValues = [];

  if (obj) {
    table.forEach(tkp => {
      if (obj[tkp.key]) {
        if (tkp.value) {
          const value = tkp.value(obj[tkp.key]);
          if (value) {
            propValues.push({ prop: tkp.text, value });
          }
        }
        else {
          propValues.push({ prop: tkp.text, value: obj[tkp.key] });
        }
      }
    });
  }

  return propValues;
}
