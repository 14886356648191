<template>
  <div class="field">
    <label class="label">
      {{ label }}
      <i v-if="required" class="has-text-danger" data-tooltip="Required">*</i>
      <span class="has-text-info is-size-7 has-text-weight-normal">
        ({{ remainingCharacters }} characters left)
      </span>
      <span v-if="lastEditor" class="has-text-success is-size-7 ml-1" :title="lastEditor.name">({{ lastEditor.last_name }})</span>
      <span v-if="sublabel" class="is-pulled-right is-size-7 mt-1">{{ sublabel }}</span>
    </label>
    <div class="control">
      <textarea v-model="form[field]" class="textarea" :required="required"
        :readonly="readonly" :maxlength="max" :rows="rows"></textarea>
    </div>
    <div class="help is-danger" v-if="$page.props.errors[field]">{{ $page.props.errors[field] }}</div>
  </div>
</template>

<script setup>
import { computed } from "@vue/runtime-core";


const props = defineProps({
  form: Object,
  label: String,
  sublabel: String,
  field: String,
  required: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: 255
  },
  rows: {
    type: [Number, String],
    default: 6
  },
  lastEditor: Object,
});

const remainingCharacters = computed(() => {
    return props.max - (props.form[props.field]?.length || 0);
});

</script>
