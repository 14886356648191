<template>

  <div class="columns">
    <div class="column has-text-weight-bold">
      <p class="is-size-4 mb-2">
        {{ listing.street_address }}
      </p>
      <p class="has-text-weight-bold">
        {{ joinTexts(', ', listing.area, listing.county) }} {{ mlsDump(listing, 'Zip', null) }}
        <span v-if="mlsDump(listing, 'Cross_st', null)" class="has-text-weight-normal"> ({{ mlsDump(listing, 'Cross_st') }})</span>
      </p>
      <p class="has-text-weight-normal mb-2">
        {{ joinTexts(', ', listing.municipality, listing.municipality_district, listing.community) }}
      </p>
      <p class="has-text-weight-bold">
        {{ joinTexts(
          ', ',
          (mlsDump(listing, 'Type_own1_out', null) ?? mlsDump(listing, 'Type_own_srch', null)),
          mlsDump(listing, 'Style', null)
        ) }}
        <span class="is-family-code has-text-grey">&bull; {{ listing.mls_num }}</span>
      </p>
    </div>
    <div class="column is-narrow">
      <h2 class="title has-text-weight-bold" :class="getListingTextColor(listing)">
        {{ formatter.currency(isSoldOrLeased ? listing.sold_price : listing.price) }}
      </h2>
      <h3 class="subtitle is-size-6 has-text-weight-bold">

        <p>
          {{ formatter.date(listing.date, 'MMM D, YYYY') }}
          <template v-if="listing.dom">({{ listing.dom }}d)</template>
        </p>

        <p v-if="listing.status_text" :class="getListingTextColor(listing)"
          >{{ listing.status == 'Ext' ? 'New' : listing.status_text }}</p>

        <p v-if="isSoldOrLeased && listing.price != listing.sold_price"
          :class="{'has-text-success': originalPrice && originalPrice != listing.price}"
          ><span class="has-line-through">{{ formatter.currency(listing.price) }}</span></p>

        <p v-if="mlsDump(listing, 'Cond', null) && (listing.status == 'Sc' || listing.status == 'Lc' || listing.status == 'Sce' || listing.status == 'Lce')"
          :class="getListingTextColor(listing)">{{ mlsDump(listing, 'Cond') }}</p>

        <p v-if="originalPrice && originalPrice != listing.price"
          ><span class="has-line-through">{{ formatter.currency(originalPrice) }}</span></p>

        <p v-if="isSoldOrLeased" :class="getListingTextColor(listing)">
          {{ formatter.date(listing.closing_date, 'MMM D, YYYY') }} (Closing)
        </p>
        <p v-else-if="lastListingDate != listing.date" :class="getListingTextColor(listing)">
          {{ formatter.date(lastListingDate, 'MMM D, YYYY') }}
        </p>
      </h3>
    </div>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { getListingTextColor, getListingStreetAddress, mlsDump } from '../../../Scripts/listing.js';
import { createFormatter } from '../../../Scripts/formatter.js';

const props = defineProps({
  listing: Object,
});

const formatter = createFormatter();
const originalPrice = ref(mlsDump(props.listing, 'Orig_dol', null));
const isSoldOrLeased = props.listing.is_sold || props.listing.is_leased;
const lastListingDate = ref(null);

if (isSoldOrLeased)
  lastListingDate.value = props.listing.sold_date;
else if (props.listing.is_active)
  lastListingDate.value = props.listing.date;
else
  lastListingDate.value = props.listing.unavailable_date;

function joinTexts() {
  const values = [];
  for (var i = 1; i < arguments.length; i++)
    if (arguments[i])
      values.push(arguments[i]);
  return values.join(arguments[0]);
}

</script>
