<template>
  <div class="columns">
    <div class="column">

     <ImageGallery v-if="isMobile" :images="data.listing.images" />

      <ShowListingBox class="pb-4">
        <ListingHeader :listing="data.listing" />
        <ListingSubHeader :listing="data.listing" :favorite-listing-ids="data.favoriteListingIds" :formatter="formatter" />

        <div class="columns">
          <div class="column">
            <ListingInfo :listing="data.listing" :formatter="formatter" />
          </div>
          <div class="column is-one-third">
            <ListingAmenitiesFeaturesInclusions :listing="data.listing" :formatter="formatter" />
          </div>
        </div>
      </ShowListingBox>

      <ShowListingBox v-if="data.history.length > 1" title="History" class="pb-4">
        <ListingHistoryInfo :listing="data.listing" :history="data.history" :formatter="formatter" />
      </ShowListingBox>

      <ShowListingBox class="pb-4">
        <ListingRoomsInfo :listing="data.listing" :formatter="formatter" />
      </ShowListingBox>

      <ShowListingBox title="Listing Description" class="pb-4">
        <p class="content text-sm">{{ mlsDump(data.listing, 'Ad_text') }}</p>
        <p class="content text-sm">{{ mlsDump(data.listing, 'Extras') }}</p>
      </ShowListingBox>

    </div>

    <div class="column is-one-third">
      <ShowListingBox class="pb-4">

        <InquiryForm v-if="data" :listing="data.listing" />

        <button v-if="!$page.props.auth || $page.props.auth.is_client" type="button"
          @click="bookForShowing"
          :disabled="isSendingBookForShowingRequest"
          :class="{ 'is-loading': isSendingBookForShowingRequest }"
          class="button is-info is-fullwidth mt-1">Book a Viewing</button>

        <ImageGallery v-if="!isMobile" :images="data.listing.images" class="mt-3" />
      </ShowListingBox>

      <ShowListingBox v-if="data.similarListings.length" title="Similar Listings">
        <SimilarListings :similar-listings="data.similarListings" />
      </ShowListingBox>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { router } from "@inertiajs/vue3";

import InquiryForm from '../../../Components/InquiryForm.vue';
import { mlsDump } from '../../../Scripts/listing.js'
import { createFormatter } from '../../../Scripts/formatter.js'
import { detectIsMobile } from '../../../Scripts/utils.js';

import ImageGallery from '../../../Components/ImageGallery.vue'
import ShowListingBox from './ShowListingBox.vue';
import ListingHeader from './ListingHeader.vue';
import ListingSubHeader from './ListingSubHeader.vue';
import ListingAmenitiesFeaturesInclusions from './ListingAmenitiesFeaturesInclusions.vue';
import ListingInfo from './ListingInfo.vue';
import ListingRoomsInfo from './ListingRoomsInfo.vue';
import ListingHistoryInfo from './ListingHistoryInfo.vue';
import SimilarListings from './SimilarListings.vue';

const props = defineProps({
  data: Object,
  showInquiryForm: Boolean,
});

const isSendingBookForShowingRequest = ref(false);
const formatter = createFormatter();
const isMobile = detectIsMobile();

function bookForShowing() {
  isSendingBookForShowingRequest.value = true;
  router.post(route('book-for-showing-requests.store', { listing: props.data.listing.id }), {}, {
    onFinish: () => {
      isSendingBookForShowingRequest.value = false;
    }
  })
}

</script>
