<template>

  <div class="is-flex">
    <h3 class="has-text-weight-bold mb-5 is-size-4 is-flex-grow-1">Rooms Information</h3>
    <p class="control mt-2 has-text-grey is-family-code is-uppercase">
      <label class="radio">
        <input v-model="uom" type="radio" value="ft">
        Feet
      </label>
      <label class="radio">
        <input v-model="uom" type="radio" value="m">
        Meter
      </label>
    </p>
  </div>

  <div class="table-container p-0">
    <table class="table is-fullwidth is-striped">
      <thead>
        <th>No.</th>
        <th>Room</th>
        <th>Level</th>
        <th align="center">Length ({{ uom }})</th>
        <th align="center">Width ({{ uom }})</th>
        <th>Description</th>
      </thead>
      <tbody>
        <tr v-for="room in rooms" :key="room.type">
          <td>{{ room.number }}</td>
          <td class="is-nowrap">{{ room.type }}</td>
          <td>{{ room.level }}</td>
          <td align="center">{{ room.length }}</td>
          <td align="center">{{ room.width }}</td>
          <td class="is-nowrap">{{ room.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import { mlsDump } from '../../../Scripts/listing.js';

const props = defineProps({
  listing: Object,
  formatter: Object,
});

let roomNum = 0;
const rooms = ref(parseRooms());
const uom = ref('');
const bedsCount = props.listing.bedrooms_count + props.listing.bedrooms_plus_count;

watch(uom, value => {
  if (value == 'm') {
    rooms.value.forEach(room => {
      if (room.length_ft && room.width_ft) {
        room.length = room.length_ft.toFixed(2);
        room.width = room.width_ft.toFixed(2);
      }
    });
  }
  else {
    rooms.value.forEach(room => {
      if (room.length_ft && room.width_ft) {
        room.length = (room.length_ft * 3.28084).toFixed(2);
        room.width = (room.width_ft * 3.28084).toFixed(2);
      }
    });
  }
});

uom.value = 'ft'

function parseRooms() {
  const rooms = [];

  for (let i = 0; i < 20; i++) {

    roomNum = i + 1;
    let propName = `Rm${roomNum}_`;
    let roomType = mlsDump(props.listing, propName + 'out', '');

    if (!roomType)
      break;

    if (roomType == 'Living')
      roomType = 'Living Room'
    if (roomType.endsWith('Br'))
      roomType = 'Bedroom'

    let length = parseFloat(mlsDump(props.listing, propName + 'len'));
    let width = parseFloat(mlsDump(props.listing, propName + 'wth'));

    if (isNaN(length))
      length = null;
    if (isNaN(width))
      width = null;

    rooms.push({
      number: roomNum,
      type: roomType,
      length_ft: length,
      width_ft: width,
      length: length ? length.toFixed(2) : null,
      width: width ? width.toFixed(2) : null,
      level: mlsDump(props.listing, 'Level' + roomNum),
      description: mlsDump(props.listing, [
        `Rm${roomNum}_dc1_out`,
        `Rm${roomNum}_dc2_out`,
        `Rm${roomNum}_dc3_out`,
      ]),
    });
  }

  const bathrooms = parseBathrooms();

  bathrooms.forEach((r, i) => {
    rooms.push({
      number: roomNum++,
      type: 'Bathroom',
      level: r.level,
      description: r.washrooms + (r.washrooms == '-' ? '' : ' Closet P')
    });
  });

  return rooms;
}

function parseBathrooms() {
  const bathrooms = [];

  let bathroomCount = parseInt(mlsDump(props.listing, 'Bath_tot'))

  if (isNaN(bathroomCount))
    bathroomCount = 0;

  for (let i = 0; i < bathroomCount; i++) {

    let roomNum = i + 1;

    bathrooms.push({
      num: mlsDump(props.listing, `Wcloset_t${roomNum}`),
      level: mlsDump(props.listing, `Wcloset_t${roomNum}lvl`),
      washrooms: mlsDump(props.listing, `Wcloset_p${roomNum}`),
    });
  }

  return bathrooms;
}

</script>
