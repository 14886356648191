import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import env from '../env.js';

window.Pusher = Pusher;

if (env.appEnv == 'local') {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: env.pusherAppKey,
        forceTLS: false,
        wsHost: env.pusherAppHost,
        wsPort: env.pusherAppPort,
        encrypted: false,
    })
}
else {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: env.pusherAppKey,
        cluster: env.pusherAppCluster,
        wsHost: env.pusherAppHost,
        wsPort: env.pusherAppPort,
        forceTLS: true,
        disableStats: true,
    });
}
