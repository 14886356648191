<style scoped>

.dialog-content {
  max-width: 90vw;
  max-height: 80vh;
  overflow-y: auto;
}

dialog .button {
  min-width: 5rem;
}

</style>

<template>
  <dialog ref="dialog" :style="`min-width: ${width}vw;`">
    <button type="button" class="delete is-pulled-right mr-5 mt-4 is-medium"
      @click="close()" title="Close Dialog"></button>
    <header :class="'p-4 ' + (primaryAction == 'is-danger' ? 'has-background-danger-light' : 'has-background-primary-light')">
      <h3 :class="'has-text-weight-bold is-size-5 ' + (primaryAction == 'is-danger' ? 'has-text-danger-dark' : 'has-text-primary-dark')">{{ title }}</h3>
    </header>
    <section ref="contentSection" class="dialog-content"
      :class="background + (noPadding ? '' : ' p-4')">
      <slot name="content"></slot>
    </section>
    <footer v-if="okText || cancelText || $slots.actions" class="mt-5 p-4 has-background-link-light">
      <div class="buttons is-right">
        <slot v-if="$slots.actions" name="actions"></slot>
        <template v-else>
          <button v-if="okText" :class="'button ' + primaryAction + (isLoading ? ' is-loading' : '')"
            type="button" @click="ok" :disabled="isLoading">{{ okText }}</button>
          <button v-if="cancelText" class="button" type="button" @click="close">{{ cancelText }}</button>
        </template>
      </div>
    </footer>
  </dialog>
</template>

<script setup>
import { ref } from "@vue/reactivity";

const emits = defineEmits(['oked','closed']);

defineProps({
  title: String,
  okText: {
    type: String,
    default: 'OK'
  },
  cancelText: {
    type: String,
    default: 'Cancel',
  },
  primaryAction: {
    type: String,
    default: 'is-primary'
  },
  noPadding: Boolean,
  background: {
    type: String,
    default: 'has-background-white'
  },
  width: {
    type: [Number, String],
    default: 30
  }
});

defineExpose({ open, close, stopLoading });

const dialog = ref(null);
const contentSection = ref(null);
const isLoading = ref(false);

function open() {
  dialog.value.showModal();
  contentSection.value.scrollTop = 0;
  isLoading.value = false;
}

function close() {
  dialog.value.close();
  emits('closed');
}

function ok() {
  isLoading.value = true;
  emits('oked');
}

function stopLoading() {
  isLoading.value = false;
}

</script>
