export default {
  get appName() {
    return import.meta.env.VITE_APP_NAME
  },
  get appUrl() {
    return new URL(import.meta.env.VITE_APP_URL)
  },
  get appEnv() {
    return import.meta.env.VITE_APP_ENV
  },
  get pusherAppKey() {
    return import.meta.env.VITE_PUSHER_APP_KEY
  },
  get pusherAppHost() {
    return import.meta.env.VITE_PUSHER_HOST
  },
  get pusherAppPort() {
    return import.meta.env.VITE_PUSHER_PORT
  },
  get pusherAppCluster() {
    return import.meta.env.VITE_PUSHER_APP_CLUSTER
  },
  get prefix() {
    if (this.appName == 'Real Estate Game')
      return 'REG'
    return 'RE/Max'
  },
  get googleRecaptchaKey() {
    return import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY
  },
  get googleMapsKey() {
    return import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  }
}
