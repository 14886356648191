<style scoped>

.card > .tag {
  position: absolute;
  left: 0.3rem;
  top: 0.3rem;
}
.card > .is-favorite {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 10;
  color: #fff;
  text-shadow: -0.03rem 0 0.03rem #000, 0 0.03rem 0.03rem #000, 0.03rem 0 0.03rem #000, 0 -0.03rem 0.03rem #000;
}
.card > .is-viewed {
  position: absolute;
  right: 2.5rem;
  top: 0.5rem;
  z-index: 10;
  color: #fff;
  text-shadow: -0.03rem 0 0.03rem #000, 0 0.03rem 0.03rem #000, 0.03rem 0 0.03rem #000, 0 -0.03rem 0.03rem #000;
}

.card .timestamp {
  position: absolute;
  bottom: 0.2rem;
  left: 0.5rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 0.3rem;
}

:deep(.carousel__icon) {
  color: #fff;
}

</style>

<template>
  <div class="card is-relative" :class="{'is-viewed' : isViewed, blurred: (listing.is_sold || listing.is_leased) && $page.props.auth == null}">

    <span class="tag is-dark"
      :class="getListingBackgroundColor(listing)">{{ listing.status_text }}</span>

    <span v-if="!isFavoring" class="icon is-size-4 is-favorite is-clickable"
      :class="{'has-text-danger': isFavoriteListing}"
      @click.prevent.stop="favoriteListing">
      <i v-if="isFavoriteListing" class="fas fa-heart"></i>
      <i v-else class="far fa-heart"></i>
    </span>

    <span v-if="isViewed" class="icon is-size-4 is-viewed" title="You already viewed this listing">
      <i class="fas fa-eye"></i>
    </span>

    <div class="card-image">
      <Carousel v-if="listing.images.length" :items-to-show="1" v-model="currentImageIndex">
        <Slide v-for="image in listing.images" :key="image.id">
          <figure class="image is-4by3 is-fullwidth is-clickable" @click.prevent="openListing">
            <VLazyImage :src="image.url" :alt="image.name" />
          </figure>
        </Slide>
        <template #addons="{ slidesCount }">
          <Navigation v-if="slidesCount > 1" />
        </template>
      </Carousel>
      <div v-else class="image is-4by3 is-fullwidth is-clickable" @click.prevent="openListing">
        <p class="no-image">
          NO IMAGE AVAILABLE
        </p>
      </div>
      <span class="timestamp is-size-7">Updated {{ formatter.utcToLocal(listing.mls_timestamp, 'ago') }}</span>
    </div>

    <div class="card-content p-3">
      <a :href="listingUrl" @click.prevent="openListing" class="content has-text-black">

        <div class="is-flex is-justify-content-space-between">

          <span class="title m-0 is-size-5 is-nowrap has-text-weight-bold"
            :class="getListingTextColor(listing)">
            ${{ formatter.number(listing.last_price) }}
          </span>

          <span class="icon-text">
            <span>MLS#{{ listing.mls_num }}</span>
            <span class="icon">
              <i v-if="listing.type == 'Condo'" class="far fa-building"></i>
              <i v-else class="fas fa-house"></i>
            </span>
          </span>
        </div>

        <div class="is-clipped is-nowrap">
          {{ listing.address }}
        </div>

        <div class="is-clipped is-nowrap" :title="listing.realtor?.name">
          {{ listing.realtor?.name }}
        </div>

        <hr class="mt-1 mb-1">
        <div class="is-flex is-size-7 is-justify-content-space-between">
          <span class="icon-text">
            <span class="icon">
              <i class="fas fa-bed"></i>
            </span>
            <span>
              {{ listing.bedrooms_count }}
              {{ listing.bedrooms_plus_count ? '+ ' + listing.bedrooms_plus_count : '' }}
              Bed{{ listing.bedrooms_count > 1 ? 's' : '' }}
            </span>
          </span>
          <span class="icon-text">
            <span class="icon">
              <i class="fas fa-bath"></i>
            </span>
            <span>{{ listing.bathrooms_count }} Bath</span>
          </span>
          <span class="icon-text">
            <span class="icon">
              <i class="fas fa-ruler"></i>
            </span>
            <span>{{ formatter.number(listing.sqft_range_from) ?? '-' }} sqft</span>
          </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>

import 'vue3-carousel/dist/carousel.css'

import VLazyImage from 'v-lazy-image';
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { router } from '@inertiajs/vue3';
import { ref } from '@vue/reactivity';

import { toggleFavoriteListing, getListingTextColor, getListingBackgroundColor, getListingAddress } from '../../../Scripts/listing.js';
import { createFormatter } from '../../../Scripts/formatter.js';
import { usePage } from '@inertiajs/vue3';
import env from '../../../env.js';

const formatter = createFormatter()

const props = defineProps({
  listing: Object,
});

const favoriteListingIds = router.page.props.favoriteListingIds || [];
const isFavoriteListing = ref(favoriteListingIds.includes(props.listing.id));
const isFavoring = ref(false);
const listingUrl = (props.listing.is_sold || props.listing.is_leased)
  && usePage().props.auth == null
  ? route('login')
  : route('listings.show', { listing: props.listing.id });
const emits = defineEmits(['unfavorite', 'open']);
const isViewed = ref(!!props.listing.viewers?.find(v => v.id == router.page.props.auth?.id));
const currentImageIndex = ref(0)

function favoriteListing() {
  isFavoring.value = true;
  toggleFavoriteListing(props.listing.id, isFavoriteListing.value)
    .then(res => {
      if (isFavoriteListing.value)
        emits('unfavorite', props.listing)
      isFavoriteListing.value = res;
      isFavoring.value = false;
    });
}

function openListing(e) {
  emits('open', { listing: props.listing });
  if (router.page.props.auth) {
    isViewed.value = true;
  }
}

</script>
