<style scoped>

.backdrop {
  background-color: #000;
  opacity: 0.4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.message {
  position: absolute;
  top: 3rem;
  left: 1rem;
}
.message-body {
  border: 2px solid #00d1b2;
}

textarea {
  resize: none;
  width: 25rem;
}

</style>

<template>

  <div v-if="visible" class="backdrop" @click="hideMessageBox"></div>

  <button class="button is-primary is-inverted ml-1 mr-2" @click="showMessageBox">
    <span class="icon">
      <i class="fas fa-id-card is-size-3"></i>
    </span>
  </button>

  <div v-if="visible" class="message is-primary">
    <div class="message-header py-2">
      <p>Welcome {{ $page.props.auth.first_name }}!</p>
      <button type="button" class="delete" @click="hideMessageBox"></button>
    </div>
    <div class="message-body p-2">
      <p class="py-2 px-2">
        <strong>{{ primaryAgent.first_name }} {{ primaryAgent.last_name }}</strong>:
        I'm always a click away on the top left of the screen so reach out
        if you have any further questions about anything.
      </p>
      <textarea v-model="message" ref="textarea" class="textarea"
        :placeholder="`Type your message or inquiry here to ${primaryAgent.first_name}`"></textarea>
      <div class="buttons is-right mt-2">
        <button type="button" class="button is-primary" @click="sendMessage">Send Message</button>
      </div>
    </div>
  </div>
</template>

<script setup>

import { router, usePage } from '@inertiajs/vue3';
import axios from 'axios';
import { computed, nextTick, ref } from 'vue';

const textarea = ref(null);
const visible = ref(false);
const message = ref(null);
const primaryAgent = computed(() => {
  const agents = usePage().props.agents;

  if (agents.length == 1)
    return agents[0];

  const paid = usePage().props.primary_agent_id;
  const agent = agents.find(a => a.id == paid);

  if (agent) return agent;

  return agents[0];
});

if (usePage().props.showMessenger) {
  showMessageBox();
  axios.put(route('messenger.update'));
}

function showMessageBox() {
  if (visible.value) {
    hideMessageBox();
  }
  else {
    visible.value = true;
    message.value = null;
    nextTick(() => {
      textarea.value.focus();
    });
  }
}

function hideMessageBox() {
  visible.value = false;
}

function sendMessage() {
  router.post(route('messenger.send.agent'), { message: message.value }, {
    onSuccess() {
      hideMessageBox();
    },
  });
}

</script>
