<style scoped>
table td:first-child {
  color: #777;
}
table td:last-child {
  font-weight: bold;
}
</style>

<template>

  <div class="table-container">
    <table class="table is-narrow is-fullwidth is-striped is-bordered">
      <tbody>
        <template v-if="listing.property?.building">
          <tr>
            <td>Building</td>
            <td>{{ listing.property.building.name }}</td>
          </tr>
          <tr>
            <td>Property Management</td>
            <td>{{ mlsDump(listing, 'Prop_mgmt') }}</td>
          </tr>
          <tr>
            <td>Condo Corporation</td>
            <td>{{ mlsDump(listing, 'Condo_corp') }} {{ mlsDump(listing, 'Corp_num') }}</td>
          </tr>
        </template>
        <tr v-if="mlsDump(listing, 'Fractional_ownership', null)">
          <td>Fractional Ownership</td>
          <td>{{ mlsDump(listing, 'Fractional_ownership') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'S_name', null)">
          <td>Seller's Name</td>
          <td>{{ mlsDump(listing, 'S_name') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Unit_num', null)">
          <td>Property Number (Legal)</td>
          <td>{{ mlsDump(listing, 'Unit_num') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Poss_date', null)">
          <td>Possession Date</td>
          <td>{{ formatter.date(mlsDump(listing, 'Poss_date'), 'MMM. D, YYYY') }}</td>
        </tr>
        <tr v-if="listing.closing_date">
          <td>Closing Date</td>
          <td>{{ formatter.date(listing.closing_date, 'MMM. D, YYYY') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Taxes', null)">
          <td>
            Property Taxes
            <span v-if="mlsDump(listing, 'Cond_txinc', 'N') != 'N'"
              class="icon has-text-primary-dark is-clickable has-tooltip-arrow"
              data-tooltip="Taxes Included">
              <i class="fas fa-info-circle"></i>
            </span>
          </td>
          <td>{{ formatter.currency(mlsDump(listing, 'Taxes')) }} ({{ mlsDump(listing, 'Yr') }})</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Assignment', null)">
          <td>Assignment</td>
          <td>{{ mlsDump(listing, 'Assignment') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Maint', null)">
          <td>Maintenance Fee</td>
          <td>
            {{ formatter.currency(mlsDump(listing, 'Maint')) }}
            <span v-if="mlsDump(listing, 'Addl_mo_fee', null)">+ {{ mlsDump(listing, 'Addl_mo_fee') }}</span>
            /mo.
          </td>
        </tr>
        <tr v-if="mlsDump(listing, 'Com_coopb', null)">
          <td>Coop</td>
          <td>{{ mlsDump(listing, 'Com_coopb') }}</td>
        </tr>
        <tr v-if="propertyPortionLeases.length">
          <td>Property Portion Lease</td>
          <td>
            {{ propertyPortionLeases.join(', ') }}
            <span v-if="mlsDump(listing, 'Portion_lease_comments', null)">
              <br>Portion Lease Comments: {{ mlsDump(listing, 'Portion_lease_comments') }}
            </span>
          </td>
        </tr>
        <tr v-if="mlsDump(listing, 'Condo_exp', null)">
          <td>Condo Exposure</td>
          <td class="is-uppercase">{{ mlsDump(listing, 'Condo_exp') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Patio_ter', null)">
          <td>Outdoor Space</td>
          <td>{{ mlsDump(listing, 'Patio_ter') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Bsmt1_out', null)">
          <td>Basement</td>
          <td>
            {{ mlsDump(listing, 'Bsmt1_out') }}
            <template v-if="mlsDump(listing, 'Bsmt2_out', null)">/ {{ mlsDump(listing, 'Bsmt2_out') }}</template>
          </td>
        </tr>

        <tr v-if="mlsDump(listing, 'Tot_park_spcs', null)">
          <td class="has-text-grey">Total parking</td>
          <td class="has-text-weight-bold">{{ parseInt(mlsDump(listing, 'Tot_park_spcs')) }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Park_fac', null)">
          <td class="has-text-grey">Parking Type</td>
          <td class="has-text-weight-bold">{{ mlsDump(listing, 'Park_fac') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Park_chgs', null)">
          <td class="has-text-grey">Monthly Fee</td>
          <td class="has-text-weight-bold">{{ mlsDump(listing, 'Park_chgs') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Park_desig', null)">
          <td class="has-text-grey">Parking Designation</td>
          <td class="has-text-weight-bold">
            {{ mlsDump(listing, 'Park_desig') }}
            <template v-if="mlsDump(listing, 'Park_lgl_desc1', null)">, Level {{ mlsDump(listing, 'Park_lgl_desc1') }}</template>
            <template v-if="mlsDump(listing, 'Park_spc1', null)">, Property {{ mlsDump(listing, 'Park_spc1') }}</template>
          </td>
        </tr>
        <tr v-if="mlsDump(listing, 'Park_desig_2', null)">
          <td class="has-text-grey">Parking Designation 2</td>
          <td class="has-text-weight-bold">
            {{ mlsDump(listing, 'Park_desig_2') }}
            <template v-if="mlsDump(listing, 'Park_lgl_desc2', null)">, Level {{ mlsDump(listing, 'Park_lgl_desc2') }}</template>
            <template v-if="mlsDump(listing, 'Park_spc2', null)">, Property {{ mlsDump(listing, 'Park_spc2') }}</template>
          </td>
        </tr>

        <tr v-if="mlsDump(listing, 'Gar', null)">
          <td class="has-text-grey">Garage</td>
          <td class="has-text-weight-bold">{{ mlsDump(listing, 'Gar') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Gar_type', null)">
          <td class="has-text-grey">Garage Type</td>
          <td class="has-text-weight-bold">{{ mlsDump(listing, 'Gar_type') }}</td>
        </tr>
        <tr>
          <td class="has-text-grey">Parking Spaces</td>
          <td class="has-text-weight-bold">{{ mlsDump(listing, 'Park_spcs') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Stories', null)">
          <td class="has-text-grey">Stories</td>
          <td class="has-text-weight-bold">{{ mlsDump(listing, 'Stories') }}</td>
        </tr>
        <tr v-if="mlsDump(listing, 'Locker', null)">
          <td class="has-text-grey">Locker Type</td>
          <td class="has-text-weight-bold">
            {{ mlsDump(listing, 'Locker') }}
            <template v-if="mlsDump(listing, 'Locker_num', null)">#{{ mlsDump(listing, 'Locker_num') }}</template>
            <template v-if="mlsDump(listing, 'Locker_lev_unit', null)">, Level {{ mlsDump(listing, 'Locker_lev_unit') }}</template>
            <template v-if="mlsDump(listing, 'Locker_unit', null)">, Property {{ mlsDump(listing, 'Locker_unit') }}</template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { mlsDump } from '../../../Scripts/listing.js';

const props = defineProps({
  listing: Object,
  formatter: Object,
});

const propertyPortionLeases = ref(getPropertyPortionLeases());

function getPropertyPortionLeases() {
  const values = [];
  for (let i = 1; i <= 4; i++) {
    let value = mlsDump(props.listing, `Portion_property_lease${i}_out`, '');
    if (value)
      values.push(value);
  }
  return values;
}

</script>
