<style scoped>
.notification {
  z-index: 10001;
}
</style>

<template>
  <Transition name="slide-fade fade">
    <div v-if="toast.message" :class="'notification is-floating ' + toast.class">
      <button class="delete" @click="toast.message = null"></button>
      {{ toast.message }}
    </div>
  </Transition>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import { reactive, watch } from "@vue/runtime-core";

const toast = reactive({
  message: null,
  class: '',
});

watch(() => usePage().props.flash, flash => {
  toast.class = 'is-dark';
  if (flash.info) {
    toast.message = flash.info;
  }
  else if (flash.message) {
    toast.message = flash.message;
  }
  else if (flash.error) {
    toast.message = flash.error;
    toast.class = 'is-danger';
  }

  setTimeout(() => toast.message = null, 10000);
});

</script>
