<style scoped>
.fas:hover {
  color: lightgreen !important;
}
</style>

<template>
  <div class="modal" :class="{ 'is-active': showModalImage }">
    <div class="modal-background" @click="showModalImage = false"></div>
    <div class="modal-content">
      <div class="is-flex is-justify-content-center is-align-items-center">
        <span class="icon mr-5 is-clickable" @click="prevImage">
          <i class="fas fa-chevron-left has-text-white is-size-3"></i>
        </span>
        <figure v-if="image" class="image is-4by3 is-flex-grow-1" :key="image.url">
          <img :src="image.url" :alt="image.name" />
        </figure>
        <span class="icon ml-5 is-clickable" @click="nextImage">
          <i class="fas fa-chevron-right has-text-white is-size-3"></i>
        </span>
      </div>
    </div>
    <button @click="showModalImage = false" type="button" class="modal-close is-large"></button>
  </div>
</template>

<script setup>

import { computed, onMounted, onUnmounted, ref } from 'vue';

const showModalImage = ref(false);

defineExpose({ open });

const props = defineProps({
  images: Array,
  initialImageIndex: Number,
});

onMounted(() => window.addEventListener('keydown', handleArrowKeys));
onUnmounted(() => window.removeEventListener('keydown', handleArrowKeys));

const imageIndex = ref(0);
const image = computed(() => props.images[imageIndex.value]);

function open() {
  imageIndex.value = props.initialImageIndex;
  showModalImage.value = true;
}

function handleArrowKeys(e) {
  if (showModalImage.value) {
    if (e.key == 'ArrowRight') nextImage();
    else if (e.key == 'ArrowLeft') prevImage();
    else if (e.key == 'Escape') {
      e.preventDefault();
      showModalImage.value = false;
    };
  }
}

function prevImage() {
  selectImage(imageIndex.value - 1);
}

function nextImage() {
  selectImage(imageIndex.value + 1);
}

function selectImage(index) {

  if (index >= props.images.length) {
    index = 0;
  }
  else if (index < 0) {
    index = props.images.length - 1;
  }

  imageIndex.value = index;
}

</script>
