<template>
  <GoogleMap ref="gmapRef"
    style="width: 100%; height:60vh;"
    :api-key="env.googleMapsKey"
    :center="center"
    :zoom="zoom" @click="handleClick">
    <Marker :key="JSON.stringify(location)" :position="location" :options="{ position: location }" />
  </GoogleMap>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import { GoogleMap, Marker } from 'vue3-google-map';
import env from "../env.js";

const props = defineProps({
  position: Object,
  allowSelect: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(['location-selected']);
const gmapRef = ref(null);
const center = reactive({
  lat: props.position?.lat ?? 43.7181557,
  lng: props.position?.lng ?? -79.5181422,
});
const location = reactive({
  lat: props.position?.lat ?? 43.7181557,
  lng: props.position?.lng ?? -79.5181422,
});
const zoom = ref(12);

watch(() => gmapRef.value?.ready, ready => {
  if (!ready) return;
  gmapRef.value.map.setOptions({
    draggableCursor: 'crosshair',
    scrollwheel: true,
  });
});

function handleClick(event) {
  if (props.allowSelect) {
    location.lat = event.latLng.lat();
    location.lng = event.latLng.lng();
    emit('location-selected', event);
  }
}

</script>
