<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h4 class="title is-5">Contact</h4>
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-home"></i>
            </span>
            <span>RE/Max Real Estate Solutions Brokerage Inc.</span>
          </div>
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-user"></i>
            </span>
            <span>Tim Yew, Broker</span>
          </div>
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-phone"></i>
            </span>
            <a href="tel:647-259-8806" target="__blank">647-259-8806</a>
          </div>
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-envelope"></i>
            </span>
            <a href="mailto:tim@realestatesolution.ca" target="__blank">tim@realestatesolution.ca</a>
          </div>
        </div>
        <div class="column">
          <h4 class="title is-5">Links</h4>
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-link"></i>
            </span>
            <Link href="/terms-of-use">Terms of Use</Link>
          </div>
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-link"></i>
            </span>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </div>
          <div class="icon-text has-text-info-light">
            <span class="icon">
              <i class="fas fa-link"></i>
            </span>
            <span>REDCAT</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
