import '../css/app.css';
import 'vue-diff/dist/index.css';
import './Scripts/echo.js';

import { createApp, h } from 'vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createInertiaApp, Link } from '@inertiajs/vue3';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import VueDiff from 'vue-diff';

import DefaultLayout from './Components/Layouts/DefaultLayout.vue';
import env from './env.js';

createInertiaApp({

  title: title => `${env.prefix} - ${title}`,

  progress: {
    color: 'green',
    showSpinner: true,
  },

  resolve: name => {

    const page = resolvePageComponent(
      `./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')
    );

    page.then(module => {
      let layout = module.default.layout;
      if (!layout && !name.endsWith('Error')) {
        layout = DefaultLayout;
        module.default.layout = layout;
      }
    });

    return page;
  },

  setup({ el, App, props, plugin }) {

    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(VueReCaptcha, { siteKey: env.googleRecaptchaKey })
      .use(autoAnimatePlugin)
      .use(VueDiff)
      .mixin({ methods: { route } })
      .component('Link', Link)
      .mount(el);
  },

});

