<template>
  <ListingCard v-for="listing in similarListings" :key="listing.id"
    :listing="listing" @open="openListing" class="mb-5" />
</template>

<script setup>

import { router } from '@inertiajs/vue3';
import ListingCard from './ListingCard.vue';

defineProps({
  similarListings: Array,
});

function openListing(e) {
  router.get(route('listings.show', { listing: e.listing.id }));
}

</script>
