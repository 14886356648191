import moment from "moment";

let formatter = null;

export function createFormatter() {

  if (formatter == null) {

    formatter = {

      _number: new Intl.NumberFormat(),

      _currency: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),

      _currencyDecimal: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),

      number: function (value, shortened) {

        if (value === null || value === undefined)
          return null;

        if (shortened) {
          if (value > 1000000) {
            return (value / 1000000).toFixed(2) + 'M+'
          }
          if (value > 1000) {
            return Math.floor(value / 1000) + 'K+'
          }
        }
        const formatted = this._number.format(value);
        if (formatted == 'NaN')
          return '-';

        return formatted
      },

      fileSize: function(sizeInBytes) {

        const units = ['B', 'KB', 'MB', 'GB']
        let size = sizeInBytes
        let unitIndex = 0

        while (size >= 1024 && unitIndex < units.length - 1) {
          size /= 1024
          unitIndex++
        }

        return `${size.toFixed(2)} ${units[unitIndex]}`
      },

      currency: function (value, withDecimal) {
        let formatted = null
        if (withDecimal === true)
          formatted = this._currencyDecimal.format(value)
        else
          formatted = this._currency.format(value)
        if (formatted == '$NaN')
          return '-';
        if (!withDecimal && formatted.endsWith('.00')) {
            formatted = formatted.replace('.00', '')
        }
        return formatted
      },

      percentage: function(value, decimalPlaces) {
        if (value === undefined || value === null || isNaN(value)) return '-'
        if (!decimalPlaces) decimalPlaces = 2
        if (value == 0) return '-'
        return (value * 100).toFixed(decimalPlaces) + '%'
      },

      date: function (value, format) {
        if (!value) return '-'
        const date = moment(value)
        if (date.isValid() == false)
          return ''
        if (format)
          return date.format(format)
        return date.calendar()
      },

      utcToLocal: function (value, format) {
        if (format) {
          if (format == 'ago')
            return moment.utc(value).local().fromNow()
          if (format == 'dd') {
            const days = moment.utc(value).diff(moment(), 'days')
            return days + (days > 1 ? ' days' : ' day')
          }
        }
        return moment.utc(value).local().format('MMM DD YYYY hh:mm A');
      },

      dateExact: function(value, format) {
        if (!value) return '';
        const m = value.length > 10
          ? moment(new Date(value.slice(0, -1)))
          : moment(value);
        if (format) {
          if (format == 'ago')
            return m.fromNow()
          return m.format(format)
        }
        return m.format('MM/DD/YYYY');
      },
    }
  }

  return formatter;
}
