<template>
  <Dialog ref="dialog" title="Download MLS Listings" ok-text="DOWNLOAD"
    @oked="downloadListing">
    <template #content>

      <div class="field">
        <label class="label">MLS Number</label>
        <div class="control">
          <input v-model="mlsNum" class="input" type="text" style="width: 20rem;">
        </div>
        <p v-if="errorMessage" class="has-text-danger">{{ errorMessage }}</p>
      </div>

      <div class="field">
        <label class="label">Listing Type</label>
        <div class="select is-fullwidth">
          <select v-model="listingType">
            <option value="Condo">Condo</option>
            <option value="Home">Freehold</option>
          </select>
        </div>
      </div>

      <p v-if="listing" class="has-text-success">
        Downloaded {{ listing.mls_num }}!
        <br>{{ listing.address }}
      </p>
    </template>
  </Dialog>
</template>

<script setup>

import { ref } from 'vue';
import Dialog from './Dialog.vue';
import axios from 'axios';

defineExpose({ open });

const dialog = ref(null);
const mlsNum = ref(null);
const listingType = ref('Condo');
const errorMessage = ref(null);
const listing = ref(null);

function open() {
  mlsNum.value = null;
  errorMessage.value = null;
  listing.value = null;
  dialog.value.open();
}

function downloadListing() {

  if (!mlsNum.value) {
    dialog.value.stopLoading();
    errorMessage.value = 'Please enter the MLS Number.';
    return;
  }

  axios.post(route('listings.download', {
    mlsNum: mlsNum.value,
    type: listingType.value,
  }))
  .catch(error => {
    errorMessage.value = 'Unable to download listing from the MLS API.';
    dialog.value.stopLoading();
    console.log(error);
  })
  .then(res => {
    listing.value = res.data;
    if (!res.data) {
      errorMessage.value = `No ${listingType.value} listing found with MLS# ${mlsNum.value}`;
    }

    dialog.value.stopLoading();
  });
}

</script>
