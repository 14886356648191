<template>

  <Dialog ref="dialog"
    :title="data ? 'MLS# ' + data.listing.mls_num : 'Loading...'"
    ok-text cancel-text background="bg-gray-100"
    :primary-action="data && data.listing.is_sold_or_leased ? 'is-danger' : 'is-primary'"
    @closed="dialogClosed">
    <template #content>

      <p v-if="data == null" class="has-text-centered">
        <span class="icon-text">
          <span class="icon">
            <i class="fas fa-spinner fa-pulse"></i>
          </span>
          <span>Loading listing data, please wait...</span>
        </span>
      </p>

      <ShowListing v-if="data" :data="data" :show-inquiry-form="showInquiryForm" />
    </template>
  </Dialog>
</template>

<script setup>

defineExpose({ show });

import { ref } from "@vue/reactivity";
import { nextTick, onBeforeUnmount, onMounted } from "@vue/runtime-core";
import axios from "axios";
import NProgress from 'nprogress';

import Dialog from '../../../Components/Dialog.vue';
import ShowListing from './ShowListing.vue';

const dialog = ref(null);
const data = ref(null);
const currentUrl = window.location.href;

defineProps({
  showInquiryForm: Boolean,
});

onMounted(() => window.addEventListener('popstate', handlePopState));
onBeforeUnmount(() => window.removeEventListener('popstate', handlePopState));

function show(listing) {
  openDialog(listing.id);
}

function openDialog(listingId) {
  NProgress.start()
  const url = route('listings.show', { listing: listingId })
  axios.get(url).then(res => {
    data.value = res.data
    nextTick(() => {
      history.replaceState({ listingId }, null, url)
      dialog.value.open()
    })
  }).finally(() => {
    NProgress.done()
  })
}

function dialogClosed() {
  history.replaceState(null, null, currentUrl);
}

function handlePopState() {
  console.log(history);
  if (history.state && history.state.listingId) {
    openDialog(history.state.listingId);
  }
  else {
    dialog.value.close();
  }
}

</script>
